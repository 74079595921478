<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="480px" transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card>
        <v-form @submit.prevent="">
          <v-card-title>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title v-text="title" />
              </v-list-item-content>
              <v-spacer />
              <v-list-item-action>
                <v-btn icon color="black" @click="$emit('close-dialog')">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-if="!isEditiong"
              v-model="form.id"
              :error-messages="getFieldErrors('id')"
              :items="availableLanguages"
              item-text="label"
              item-value="id"
              clearable
              hint
              full-width
              dense
              outlined
              style="width: 100%;"
              @update:search-input="$emit('input', $event)"
              @blur="$v.form.id.$touch()"
            />
            <html-field
              v-model="form.content"
              :placeholder="$t('common.t&c')"
              style="width: 100%;"
              :error-messages="getFieldErrors('content')"
              @blur="$v.form.content.$touch()"
            />
          </v-card-text>
          <v-card-actions class="px-6 pb-5 pt-0">
            <v-spacer />
            <v-btn color="primary" small width="120" type="submit"
                   @click="isFormFilled && $emit(isEditiong ? 'update': 'add', form); isFormFilled && $emit('close-dialog')"
                   v-text="$t('common.save')"
            />
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import formUtils from '@/utils/mixins/formUtils'
import { required } from 'vuelidate/lib/validators'
import localeUtils from '@/utils/mixins/localeUtils'
import i18nLanguages from '@/utils/mixins/i18nLanguages'

export default {
  name: 'LanguageListFormDialog',
  components: {
    HtmlField: () => import('@/components/formFields/HtmlField'),
  },
  mixins: [formUtils, localeUtils, i18nLanguages],
  props: {
    item: {
      type: Object,
      default: null, // IF null is new
    },
    languagesUsed: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        id: '',
        content: '',
      },
    }
  },
  computed: {
    isEditiong() {
      return !!this.item
    },
    isFormFilled() {
      const { id = '', content = '' } = this.form
      return id.length && content.length
    },
    title({ isEditiong }) {
      return !isEditiong ? this.$t('organization.settings.add') : this.getLanguageName(this.form.id)
    },
    availableLanguages() {
      return Object.entries(this.getLanguageList())
        .map(([languageCode, languageName]) => ({ id: languageCode, label: languageName }))
        .filter(item => !this.languagesUsed.includes(item.id))
    },
  },
  validations() {
    return {
      form: {
        id: { required },
        content: { required },
      },
    }
  },
  mounted() {
    this.form = { ...this.item }
  },
}
</script>

<style scoped>
.language-container,
.v-list-item__content {
  padding: 0% !important;
}

.tooltip-position {
  z-index: 9999;
}
</style>
